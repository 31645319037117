#Navbar {
    height: 150px;

    .navbarContainer {
        height: 100%;
        padding: 2%;
        display: flex;
        align-items: center;
        position: relative;

        .logo {
            position: absolute;
            top: 0.4rem;
            height: 140px;
            width: auto;
           
            img {
                height: 100%;
                width: 100%;
                background-size: cover;
            }
        }

        .navContent {
            margin: 0 2rem;
            margin-left: auto;
            margin-right: 13rem;

            ul {
                display: flex;
                gap: 3rem;

                li {
                    a {
                        font-size: 18px;
                    }
                }
            }
        }

        .sideContent {
            display: none;
        }
    }
}

@media only screen and (max-width: 992px) {
    #Navbar {
        .navbarContainer {
            .navContent {
                margin-right: 5rem;
                ul {
                    li {
                        a {
                            font-size: 1rem;
                        }
                        
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    #Navbar {
        .overlay {
            position: absolute;
            background-color: black;
            top: 0;
            left: 0;
            opacity: 0.6;
            height: 100vh;
            width: 100vw;
            z-index: 8;
            transition: all 0.3s ease-in-out;
            overflow-y: hidden;
        }

        .navbarContainer {
            .navContent {
                display: none;
            }

            .sideContent {
                display: flex;
                width: 100%;
                height: max-content;
                margin-top: 5rem;
                z-index: 9;

                .menuContent {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    .hide {
                        display: none;
                        position: absolute;
                        transform: translateX(100%);
                        transition: transform 0.3s ease-in-out;
                    }
    
                    .show {
                        position: absolute;
                        width: 100%;
                        top: -2rem;
                        font-weight: 500;
                        color: black;
                        height: 60vh;
                        z-index: 9999;
                        padding: 2rem;
                        border-radius: 5px;
                        transition: all 0.3s ease-in-out;
                        background-color: #FDF5F1;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: left;
                        list-style: none;
                        transition: width 1s ease-in-out;

                        li {
                            margin-bottom: 1.9rem;
                            font-size: 1.3rem;

                            a {
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 0.2em;
                                    background-color: black;
                                    opacity: 0;
                                    transition: opacity 300ms, transform 300ms;
                                }
    
                                &:hover::after,
                                &:focus::after {
                                  opacity: 1;
                                  transform: translate3d(0, 0.2em, 0);
                                }
                            }
                        }
                    }
    
                    button {
                        position: absolute;
                        right: 0;
                        top: -6.5rem;
                        z-index: 11;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            transform: scale(1.1);
                        }

                        &.menuIcon {
                            font-size: 2.5rem;
                            cursor: pointer;
                            padding: 1.5rem 1.5rem 0 1.5rem;

                            .cancelBtn {
                                svg {
                                    fill: #FDF5F1;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@media only screen and (max-width: 475px) {
    #Navbar {
        .navbarContainer {
            .logo {
                width: 170px;
                height: 120px;
            }

            .navContent {
                margin-right: 1rem;
            }
        }
    }
}

