.google-map {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .pin {
        .pin-text:not(svg) {
            transform: rotate(0deg);
            display: flex;
            position: relative;
            font-size: 1.5rem;
            font-family: 'Tiro Gurmukhi', serif;
            font-weight: 600;
            color: #CCAE86;

            svg {
                font-size: 1.8rem;
                fill: #CCAE86;
                margin-right: 0.2rem;
            }
        }
    }
}