.testimonial {
    .testimonialContainer {
        margin-top: 2rem;
        position: relative;

        .testimonialSvgLeft {
            left: -3.2rem;
            top: 5rem;
        }

        .testimonialSvgRight {
            right: 0;
            top: -5rem;
            transform: rotate(250deg);
        }
        
        .testimonailTitle {
            margin-bottom: 4rem;
            display: flex;
            padding-left: 3rem;
        }

        .testimonialWrapper {
            width: 100%;
            min-height: 680px;
            display: flex;
            justify-content: space-around;

            .testimonialCard {
                display: flex;
                flex-direction: column;

                &:hover {
                    .testimonialContent {
                        transform: translateY(-6%);
                        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                }

                &.first {
                    align-self: flex-end;
                }

                &.second {
                    align-self: center;
                }

                &.third {
                    align-self: flex-start;
                }

                .testimonialImg {
                    height: 27rem;
                    width: 28rem;
                    position: relative;
                   
                    > img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
                        transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }

                    &:hover {
                        cursor: pointer;

                        img {
                            transform: translateY(-4%);
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
                        }

                        .imgQuote, .imgQuote img {
                            transform: translateY(-20%);
                            transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
                        }
                    }

                    .imgQuote, .imgQuote img {
                        transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }

                    .imgQuote {
                        position: absolute;
                        bottom: -2.7rem;
                        right: 1rem;
                        height: 16%;
                        background-color: #CCAE86;
                        padding: 1.3rem;

                        img {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                .testimonialContent {
                    width: 28rem;
                    transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
                    
                    .clientName {
                        font-weight: 600;
                        margin: 1.4rem 0 0.6rem 0;
                        font-size: 1.3rem;
                    }

                    .clientComment {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1500px) {
    .testimonial {
        .testimonialContainer { 
            .testimonialWrapper {
                padding-left: 3rem;

                .testimonialCard {
                    &.first, &.second, &.third {
                        align-self: flex-start;
        
                        .testimonialImg {
                            width: 25rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200PX) { 
    .testimonial {
        .testimonialContainer {
            .testimonialWrapper {
                justify-content: center;
                padding-left: 0;
                width: 100%;
                flex-wrap: wrap;
                gap: 3rem;
        
                .testimonialCard {
                    &.first, &.second, &.third {
                        align-self: flex-start;
                        margin-bottom: 3rem;
                       
                        .testimonialImg {
                            width: 28rem;
                        }
                    }
                }
            }
        }
    }
}

//LAPTOP
@media only screen and (max-width: 992px) {
    .testimonial {
        .testimonialContainer {
            .testimonialSvgRight {
                top: 60%;
            }
        }
    }
}

//TABLET
@media only screen and (max-width: 768px) {

}

//PORTABLE
@media only screen and (max-width: 475px) {
    .testimonial {
        padding-left: 1rem;
        display: flex;

        .testimonialContainer {
            .testimonialWrapper {
                padding-right: 0;

                .testimonialCard {
                    justify-content: center;

                    .testimonialContent {
                        width: 100%;
                    }

                    &.first, &.second, &.third {
                        .testimonialImg {
                            width: 20rem;
                        }
                    }
                    
                }
            }
        }
    }
}