.Buttons {
    .button {
        border: 1px solid black;
        border-radius: 4px;
        padding: 1rem 3rem;
        font-weight: 600;
        transition: all .2s ease-in-out;

        &.black {
            background-color: black;
            color: whitesmoke;
        }

        &:hover {
           transform: scale3d(1.1, 1.1, 1.1) ;
           
            &.black {
                background-color: transparent;
                color: black;
            }

            &.transparent {
                background-color: black;
                color: whitesmoke;
            }
        }
    }
   

    &.horizontal {
        .button {
            margin-right: 1.2rem;
        }
    }
}

.arrowSvg {
    position: relative;
    width: 200px;
    
    img {
        position: absolute;
        height: 2.5rem;
        width: 100%;
       

        svg {
            width: 100%;
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 50%;
        border: 1px solid black;
        border-radius: 50%;
        top: 50%;
        right: -1rem;
        transform: translateY(-50%);
    }
}
