@import url('https://fonts.googleapis.com/css2?family=Tiro+Gurmukhi&display=swap');

body, html {
  overflow-x: hidden;
}

h1, h2, h3, p {
  transition: all 0.3s linear;
}

h1 {
  font-size: 42px;
  font-weight: 700;
  margin: 1.3rem 0;
  font-family: 'Tiro Gurmukhi', serif;
  word-spacing: 0.2rem;
  letter-spacing: 0.2rem;
}

h2 {
  letter-spacing: 0.1rem;
  font-size: 28px;
  font-weight: 500;
}

h3 {
  font-family: 'Tiro Gurmukhi', serif;
}


.svg {
  position: absolute;
  width: 250px;
}

.button {
  .arrowButton {
    position: absolute;
  }
}


@media only screen and (max-width: 685px) {
  .svg {
    width: 180px;
  }
}

@media only screen and (max-width: 600px) {
    h2 {
      font-size: 24px;
    }
}

@media only screen and (max-width: 475px) {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

 
}