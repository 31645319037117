.textBlob {
    position: relative;
    z-index: 2;
  
    .blobContainer {
      z-index: -1;
      position:  absolute;
      width: 210px;
      top: 50%;
      left: 50%;
      transform: translate(-10%, -50%);
    }
  }