#Contact {
    height: 100%;
    .contactContainer {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 10rem;

        .aboutSvg {
            &:nth-child(1){
                right: 0;
                bottom: 20px;
                transform: rotate(190deg);
            }

            &:nth-child(2){
                left: 0;
                bottom: 15%;
                transform: rotate(20deg);
            }
        }

        .contactCard {
            margin-bottom: 3.5rem;

            .content p {
                margin-bottom: 0.8rem;
                display: flex;
                align-items: center;
            }

            .contactInfos {
                a {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.2rem;
                }
            }

            .contactTitle {
                margin-bottom: 1.2rem;
            }
        }
    }

    svg {
        margin-right: 0.5rem;
        font-size: 1.5rem;
        fill: #CCAE86;
    }
}

.contactMap {
    height: 400px;
    width: 400px;
    margin: 2rem;
}

@media only screen and (max-width: 960px) {
    #Contact {
        .contactContainer {
            margin-top: 5rem;

            .aboutSvg {
                width: 180px;
                z-index: -1;

                &:nth-child(1){
                    bottom: 45%;
                }
    
                &:nth-child(2){
                    bottom: 70%;
                }
            }
        }

    }

    .contactMap {
        width: 100%;
        height: 450px;
    }
}

@media only screen and (max-width: 750px) {
    #Contact {
        text-align: center;

    }
}

@media only screen and (max-width: 600px) {
    #Contact {
        .contactContainer {
            .aboutSvg {
                width: 100px;

                &:nth-child(1){
                    bottom: 45%;
                }
    
                &:nth-child(2){
                    bottom: 80%;
                }
            }
        }
    }
}

@media only screen and (max-width: 475px) {
    #Contact {
        overflow-y: hidden;

        .contactContainer {
            padding: 0 1rem;
            
            .contactCard {
                .contactTitle {
                    margin-bottom: 1.7rem;
                }

                .content {
                    text-align: left;
                }
            }
        }
    }
}