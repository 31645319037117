#Home {
    height: 100%;

    .homeContainer {
        .heroHome {
            display: flex;
            height: max-content;

            .homeLeft {
                flex: 1.5;
                margin-left: 3rem;
                margin-top: 3rem;
                position: relative;

                .mainTitle {
                    font-size: 21px;
                    letter-spacing: 0.1rem;
                }
    
                .secondTitle {
                    margin-top: 1%;
                    font-size: 3rem;
                    font-weight: 700;
                }

                .Buttons {
                    margin: 3.5rem 0;
                }

                .contactListingMenu {
                    margin-right: 1.8rem;
                    padding: 4rem 0 5rem 0;

                    ul {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                       
                        li {
                            display: flex;
                            align-items: center;

                            span:nth-child(1) {
                                padding-right: 0.5rem;
                            }

                            div {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }

                .line {
                    z-index: 2;
                    position: absolute;
                    height: 1px;
                    width: 230px;
                    background-color: #D7C5B4;
                    left: 80%;
                    bottom: 35px;
                }
            }
    
            .homeRight {
                flex: 2;
                padding: 2rem;
                position: relative;
                border-top: 1px solid #D7C5B4;
                border-left: 1px solid #D7C5B4;
    
                .hero-img {
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    right: 0;
                    height: 100%;
                    z-index: 1;
                    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;

                    &::after {
                        content: "";
                        z-index: -1;
                        position: absolute;
                        left: -2.56rem;
                        bottom: 0;
                        height: 20%;
                        width: 20%;
                        border-left: 1px solid #D7C5B4;
                        border-bottom: 1px solid #D7C5B4;
                    }
    
                    img {
                        height: 110%;
                        object-fit: cover;
                        object-position: 100% 20%;
                        width: 100%;
                        overflow: hidden;
                    }
                }
            }
        }

        .aboutHome {
            position: relative;

            .aboutContent {
                padding: 15rem 0 10rem 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .aboutTitle {
                    font-size: 28px;
                    letter-spacing: 0.1rem;
                    font-weight: 500;
                }

                .aboutSubtitle {
                    font-size: 37px;
                    font-weight: 700;
                    margin: 1.3rem 0;
                }

                .aboutDescription {
                    font-size: 20px;
                    max-width: 70%;
                    opacity: 0.6;
                    font-weight: 500;
                    text-align: center;
                }
            }
            .aboutSvg {
                top: 12rem;
                right: 0;
                transform: rotate(-90deg);

                svg {
                    color: #D7C5B4;
                }
                
            }
        }

        
        //CAROUSEL

        .slider {
            padding: 40px 0;

            .sliderContainer {
                margin: 0 3rem;
                height: 100%;
                position: relative;
               
                .swiper {
                    overflow: visible;
                    
                    .imgContainer {
                        height: 100%;
                        width: 100%;
                        z-index: 2;
                        position: relative;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            
                        img {
                            object-fit: cover;
                            height: 21rem;
                            width: 100%;
                        }

                        &::after {
                            content: "";
                            z-index: -1;
                            position: absolute;
                            top: 15px;
                            right: -15px;
                            height: 100%;
                            width: 100%;
                            border: 2px solid #D7C5B4;
                            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                        }
                    }
                   
                }

                .sliderSvg {
                    z-index: 1;
                    transform: rotate(-100deg);
                    top: -10rem;
                    left: -5rem;
                }
            }
        }
    }
}

//RESPONSIVE

//MAIN
@media only screen and (max-width: 1850px) {
    .contactListingMenu {
    
        ul {
            flex-wrap: wrap;
            justify-content: flex-start;

            li {
                margin-bottom: 1.5rem;
                margin-right: 1.8rem;
            }
        }
    }
}



//LARGE SCREEN
@media only screen and (max-width: 1200PX) {
    #Home {
        .homeContainer {
            .heroHome {
                .homeRight {
                    padding: 0;
                    height: 45rem;
                    flex: 1.5 1;
                }
            }
        }
    }
}

//LAPTOP
@media only screen and (max-width: 992px) {
    #Home {
        .homeContainer {
            .heroHome {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                position: relative;
            
                .homeLeft {
                    flex: 1;
                    .contactListingMenu {
                        margin-top: 55rem;
                    }
                }
                .homeRight {
                   position: absolute;
                   top: 32%;
                   right: 0;
                   width: 90%;
                   height: 700px;
                }
            }

            .aboutHome {
                .aboutContent {
                    padding: 7rem 0;
                }

                .aboutSvg {
                    top: 1rem;
                    z-index: 1;
                }
            }
        }
    }
}

//TABLET
@media only screen and (max-width: 768px) {
    #Home {
        .homeContainer {
            .heroHome {
                .homeLeft {
                    .contactListingMenu {
                        margin-top: 0;
                        padding-top : 38rem;
                    }
                }

                .homeRight {
                   top: 35%;
                   right: 0;
                   width: 90%;
                   height: 500px;
                }
            }

            .aboutHome {
                .aboutContent {
                    text-align: center;
                    z-index: 2;
                }

                .aboutSubtitle {
                    z-index: 2;
                }

                .aboutSvg {
                    top: 1rem;
                    z-index: 1;
                    width: 10rem;
                }
            }

            .slider {
                .sliderContainer {
                    .swiper {
                        .imgContainer {
                            img {
                                max-height: 280px;
                            }
                        }
                    }
                } 
            } 
        }
    }
}

//PORTABLE
@media only screen and (max-width: 475px) {
    #Home {
        .homeContainer {
            .heroHome {
                padding-left: 1rem;
                padding-right: 1rem;

                .homeLeft {
                    margin-left: 1rem;

                    .secondTitle {
                        font-size: 2rem;
                    }

                    .Buttons {
                        display: flex;
                        flex-direction: column;
                        
                        .button {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            .aboutHome {
                padding-left: 1rem;
                padding-right: 1rem;

                .aboutContent {
                    .aboutDescription {
                        max-width: 90%;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}


  
@media screen and (min-width: 640px) {
.swiper-wrapper {
    width: 640px !important;
}
}

@media screen and (min-width: 768px) {
.swiper-wrapper {
    width: 768px !important;
}
}