.footer {
    .footerContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
            "brand map map"
            "contact map map"
            "social signature signature";
        padding: 2rem;
        color: aliceblue;

        > div {
            height: min-content;
        }

        .brandFooter {
            grid-area: brand;
            width: 225px;
            margin: 1.5rem 0;
            img {
                object-fit: cover;
                filter: invert(88%) sepia(2%) saturate(2297%) hue-rotate(194deg) brightness(104%) contrast(113%);;
            }
        }

        .mapFooter {
            grid-area: map;
            height: 300px;
            width: 400px;
            align-self: center;
            justify-self: center;
            transform: translateX(-30%);
            margin-top: 1rem;
        }

        .socialFooter {
            grid-area: social;
            display: flex;
            margin: 1rem 0;

            a {
                margin-right: 0.8rem;
                font-size: 2rem;
            }
        }

        .contactFooter {
            grid-area: contact;
            display: flex;

            ul {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
            }

            ul li {
                display: flex;
                align-items: center;
                margin: 0.6rem 0;

                span {
                    margin-right: 0.5rem;
                }
            }
        }

        .signatureFooter {
            grid-area: signature;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .footer {
        .footerContainer {
            grid-template-areas: 
            "brand brand brand"
            "map map contact"
            "map map social"
            "signature signature signature";

            .contactFooter {
                align-self: center;
                grid-column-start: 3;
            }

            .mapFooter {
                justify-self: center;
                grid-column-start: 1;
                transform: none;
            }

            .signatureFooter {
                padding: 1.5rem 0;
                
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .footer {
        .footerContainer {
            grid-template-areas: 
            "brand brand brand"
            "contact contact contact"
            "social social social"
            "map map map"
            "signature signature signature";

            .brandFooter {
               width: 190px;
            }

            .contactFooter {
                grid-column-start: 2;
                grid-column-end: 3;
            }
    
            .socialFooter {
                grid-column-start: 2;
                grid-column-end: 3;
            }
    
            .mapFooter {
                justify-self: center;
                grid-column-start: 1;
                transform: none;
                width: 100%;
            }
    
            .signatureFooter {
                padding: 1.5rem 0;
                align-self: center;
                
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .footer {
        .footerContainer {
            padding: 0;
        }
    }
}