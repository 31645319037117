#Benefit {
    height: 100%;

    .check {
        display: flex;
        align-items: center;
    }

    .benefitTitle {
        display: flex;
        margin: 1rem;
    }

    .benefitSubtitle {
        margin-bottom: 1.5rem;

        h2 {
            max-width: max-content;
        }
        
    }

    .benefitCard {
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        z-index: 2;

        .cardDesc {
            margin: 0.8rem 0.5rem;
        }
    }

    .cardContent {
        transition: all 350ms;
    }


    .mainContent {
        display: flex;
        position: relative;
        justify-content: space-around;
        margin: 1.5rem 0;
        z-index: 2;
        position: relative;
    &.left {
        margin-left: auto;
        &::after {
            content: none;
        }
    }

        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 0.1rem;
            background-color: #CCAE86;
            left: 50%;
            top: 7rem;
            transform: translate(-50%, -50%);
        }

        .effectContent {
            .benefitSubtitle h2 {
                color: inherit;
                text-decoration: underline 0.15em transparent;
                transition: all 300ms;
            }
    
            &:hover {
                .benefitSubtitle h2 {
                    text-decoration-color: black;
                    transform: scale(1.2);
                }

                .imgContent {
                    transform: translate(-1%, -2%);

                    img {
                       box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                    }

                    &::after {
                        right: -20px;
                    }
                }

                .cardContent {
                    transform: translateX(0.7rem);
                }

            }
        }

        .imgContent {
            width: 18rem;
            top: 0;
            left: 3rem;
            position: relative;
            margin-right: 4rem;
            transition: all 350ms;

            &::after {
                content: "";
                z-index: -1;
                position: absolute;
                top: 15px;
                right: -15px;
                height: 100%;
                width: 100%;
                border: 2px solid #D7C5B4;
                box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
                transition: all 370ms;
            }

            img {
                object-fit: cover;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                transition: box-shadow 0.350s;
            }
        }
    }

    .benefitContainer {
        margin-top: 7rem;
        padding-bottom: 3rem;

        .content {
            position: relative;
        }
       
        .benefitNailSvg {
            &:nth-child(1) {
                left: -3.5rem;
                top: 10rem;
            }

            &:nth-child(2) {
                right: 0;
                transform: rotate(250deg);
            }
        }

        .benefitBodySvg {
            &:nth-child(1) {
                right: 0;
                top: 30rem;
                transform: rotate(190deg);
            
            }
        }

        .benefitPackageSvg {
            &:nth-child(1) {
                left: -3rem;
                top: -12rem;
                transform: rotate(-5deg);
            }
        }
    }
}


@media only screen and (max-width: 1650px) {
    #Benefit {
        .benefitCard {
            font-size: clamp(0.7rem, 0.9rem, 1rem);

            .imgContent {
                width: 15rem;
            }
        }
    }
    .mainContent {
        &::after {
            content: none !important;
        }
    }
}

@media only screen and (max-width: 1200px) {
    #Benefit {
        .benefitCard {
            font-size: clamp(0.7rem, 1rem, 1rem);

            .imgContent {
                width: 13rem;
            }
        }
    }

}

@media only screen and (max-width: 992px) {
    #Benefit {
        .benefitCard {
            flex-direction: column;
            justify-content: flex-start;

            .imgContent {
                left: 0;
                width: 16rem;
            }

            .cardContent {
                margin-top: 2rem;
                align-self: flex-start;
            }
        }
    }

}


@media only screen and (max-width: 685px) {
    #Benefit {
        .benefitContainer {
            .benefitPackageContent {
                .mainContent {
                   .effectContent {
                        margin-bottom: 2rem;
                    }
                }
            }

            
            .benefitNailSvg:nth-child(2){
                top: 45rem;
            }
        }

        .benefitSubtitle {
            margin-bottom: 2rem;
        }

        .mainContent {
            flex-direction: column;

            .benefitCard {
                text-align: center;
                font-size: 1.2rem;

                .imgContent {
                    width: 23rem;

                    &::after {
                        top: -15px;
                    }
                }
    
                .cardContent {
                    margin-top: 2rem;
                    align-self: center;
                    text-align: center;
                }
            }
            
        }

        .check {
            align-items: flex-start;
            text-align: left;
        }
    }

}

@media only screen and (max-width: 460px) {
    .mainContent {
        .imgContent {
            width: 15rem !important;
            img {
               height: 320px;
            }
        }
    }

    .cardContent {
        margin-left: -4rem;
        padding: 0 2rem;
    }

    .cardDesc {
        font-size: 1rem;
    }

    .checkSvgContainer {
        .checkSvg {
            height: 15px !important;
            max-width: unset;
        }
    }

    .benefitPackageContent {
        .benefitSubtitle {
            margin-bottom: 1rem !important;
        }
        
        .cardContent {
            margin-top: 1rem !important;
        }

        .cardDesc {
            font-size: 0.8rem;
        }
        .checkSvgContainer {
            display: none;
        }
    }
}
